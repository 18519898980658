import React from 'react'
import { connect } from 'react-redux'

export const LanguageSet = (props) => {
    const { appData } = props.appData;
    return (
        <>
            {
                appData.translate.languages[props.code]
            }
        </>
    )
}

const mapStateToProps = (state) => ({
    appData: state.appData
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSet)