import React, { Component } from 'react'
import "../../../Assets/Css/newDashboard.css"
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import "bootstrap/scss/bootstrap.scss";
import { Menu } from 'antd';
import LanguageSet from '../../../Helpers/LanguageSet';
import axios from "axios";

import Loading from '../../../Helpers/Loading'


class SupplierMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            theme: 'light',
            current: '1',
            loading: true,
            menuData: []

        }

    }

    changeTheme = value => {
        this.setState({
            theme: value ? 'dark' : 'light',
        });
    };

    handleClick = e => {

        this.setState({
            current: e.key,
        });
    };



    componentDidMount() {

        this.getMenuItem();

    }


    getMenuItem = () => {



        this.setState({

            loading: true
        })

        axios.post(process.env.REACT_APP_API_ADDRESS + `/getMenuItems`)
            .then(res => {
                const datas = res.data;
                this.setState({
                    loading: false,
                    menuData: datas
                })
            });




    }

    parseIcon = (icon) => {


        return <i className={icon}></i>
    }




    render() {


        const { loginData, loadingLogin, isAuth } = this.props.loginData
        const { SubMenu } = Menu;

        return (
            <>


                {this.state.loading ? <Loading /> :
                    <div className='menu'>

                        {!loadingLogin && isAuth ?
                            <div className='companyInfo'>
                                <div className='avatar'>
                                    <img src="/Assets/img/tourzoneIcons.svg" alt="icon" />
                                </div>
                                <div className='infos'>
                                    <div className='companyName'>
                                        {loginData.info.companyName}
                                    </div>
                                    <div className='nameSurname'>
                                        {loginData.info.officalName} {loginData.info.officalSurname}
                                    </div>

                                    {
                                        loginData.info.approved === 1 ?
                                            <span className="badge bg-primary"><LanguageSet code="LLM_APPROVED" /></span>
                                            :
                                            <span className="badge bg-warning"><LanguageSet code="LLM_WAITING" /></span>
                                    }

                                </div>
                            </div>
                            : ""}
                        <Menu
                            theme={this.state.theme}
                            onClick={this.handleClick}
                            style={{ width: 250 }}
                            defaultOpenKeys={['sub1']}
                            selectedKeys={[this.state.current]}
                            mode="inline"
                        >
                            {this.state.menuData.map((item, index) => (

                                <>
                                    {item.subMenu.length === 0 ?
                                        <Menu.Item key={item.key} icon={this.parseIcon(item.icon)}>
                                            <Link to={"/" + item.link}><LanguageSet code={item.moduleName} /></Link>
                                        </Menu.Item>
                                        :
                                        <SubMenu key={item.key} icon={this.parseIcon(item.icon)} title={<LanguageSet code={item.moduleName} />}>

                                            {item.subMenu.map((subitems, subIndex) => (
                                                <Menu.Item key={subitems.key}>
                                                    <Link to={"/" + subitems.link}><LanguageSet code={subitems.moduleName} /></Link>
                                                </Menu.Item>
                                            ))}
                                        </SubMenu>

                                    }

                                </>

                            ))}

                        </Menu>
                    </div>
                }
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    loginData: state.loginData,
    languageData: state.languageData
})



export default connect(mapStateToProps)(SupplierMenu)