import React, { Component } from 'react'
import "../../../Assets/Css/newDashboard.css"
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import { logout } from "../../Login/Actions/LoginAction"
import jwt_decode from "jwt-decode";
import "bootstrap/scss/bootstrap.scss";
import { Menu, Dropdown, Input, AutoComplete, Button } from 'antd';
import { DownOutlined, TranslationOutlined, LogoutOutlined, DeploymentUnitOutlined } from '@ant-design/icons';
import LanguageSet from '../../../Helpers/LanguageSet';

class SupplierHeader extends Component {

    constructor() {
        super();

        this.state = {
            languageData: null,
            loading: true,
            isLanguageOpen: false,
            isProfileOpen: false,
            username: "",
            tokenError: false

        }
    }


    changeLanguage = (langCode) => {

        localStorage.setItem("langCode", langCode);
        window.location.reload();

    }
    showHideLanguage = () => {
        this.setState({
            isLanguageOpen: !this.state.isLanguageOpen
        })
    }

    showHideProfileMenu = () => {
        this.setState({
            isProfileOpen: !this.state.isProfileOpen
        })
    }

    logOut = () => {
        this.props.logout();
    }

    tokens() {
        try {
            let decode = jwt_decode(localStorage.getItem("token"));

            return decode.data.name;
        } catch (error) {
            return false;
        }
    }


    logOut = () => {
        this.props.logout();
    }


    changeLanguage = (langCode) => {

        localStorage.setItem("langCode", langCode);
        window.location.reload();

    }



    render() {
        const { isAuth, loginData } = this.props.loginData;
        return (


            <>
                <div className='container-fluid supplierHeader'>
                    <div className='headers'>
                        <div className='left'>
                            <Link to="/">

                                <img src="/Assets/img/logo_white.svg" alt="Tourzone Logo" />

                            </Link>
                        </div>
                        <div className='middle'>
                            {
                                /*
                            <AutoComplete
                                dropdownMatchSelectWidth={252}
                                style={{ width: 300 }}
                            /*
                            options={thioptions}
                            onSelect={onSelect}
                            onSearch={handleSearch}
                          
                            >
                                <Input.Search size="large" placeholder={<LanguageSet code="LHM_SEARCH_PLACEHOLDER"/>} enterButton />
                            </AutoComplete>
                            */}
                        </div>
                        <div className='right'>
                            <ul>
                                <li>
                                    <Dropdown overlay={
                                        <Menu>

                                            <Menu.Item>
                                                <Link to="/Profile"><LanguageSet code="LHM_DROP_DOWN_COMPANY" /></Link>
                                            </Menu.Item>
                                            <Menu.Item >
                                                <Link to="/Contact"><LanguageSet code="LHM_DROP_DOWN_CONTACT" /></Link>
                                            </Menu.Item>
                                            <Menu.Item danger>
                                                <Link to="/Payment"><LanguageSet code="LHM_DROP_DOWN_PAYMENT" /></Link>
                                            </Menu.Item>
                                            <Menu.Item danger>
                                                <Link to="/Documents"><LanguageSet code="LHM_DROP_DOWN_DOCUMENTS" /></Link>
                                            </Menu.Item>
                                        </Menu>
                                    }>

                                        {isAuth ?

                                            <Button type="link" className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                                <DeploymentUnitOutlined />    {loginData.info.email} <DownOutlined />
                                            </Button>
                                            :
                                            ""
                                        }
                                    </Dropdown>
                                </li>
                                <li>|</li>
                                <li className='me-5'>


                                    {isAuth ?
                                        <Link to="/Login" onClick={() => this.logOut()}>
                                            <LogoutOutlined />   <LanguageSet code="LHM_LOGOUT" />
                                        </Link>
                                        :
                                        ""
                                    }

                                </li>
                                <li>
                                    <Dropdown overlay={
                                        <Menu>
                                            <Menu.Item key="1001">
                                                <div onClick={() => this.changeLanguage("en-EN")}>English</div>
                                            </Menu.Item>
                                            <Menu.Item key="1002">
                                                <div onClick={() => this.changeLanguage("tr-TR")}>Türkçe</div>
                                            </Menu.Item>

                                        </Menu>
                                    }>

                                        {isAuth ?

                                            <Button type="link" className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                                <TranslationOutlined />  <LanguageSet code="LHM_LANGUAGE" /> <DownOutlined />
                                            </Button>
                                            :
                                            ""
                                        }
                                    </Dropdown>
                                </li>
                            </ul>



                        </div>
                    </div>

                </div>
            </>

        )
    }
}


const mapStateToProps = (state) => ({
    loginData: state.loginData,

});

export default connect(mapStateToProps, { logout })(SupplierHeader);
